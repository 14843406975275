<template>
    <div ref="body">
        <loading :active="isLoading"
                 :can-cancel="false"
                 :is-full-page="fullPage"></loading>
        <nav class="navbar navbar-expand-lg navbar-dark">
            <div class="container-xl">
                <div class="navbar-brand">實聯快疫通<small class="ml-3">Speed PASS</small></div>

                <!--m_menu-->
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExample07XL" aria-controls="navbarsExample07XL" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <!--end m_menu-->

                <div class="collapse navbar-collapse" id="navbarsExample07XL">
                    <ul class="navbar-nav mr-auto">
                        <li class="nav-item">
                            <a class="nav-link" href="mailto:info@yscreatech.com?subject=「實聯快疫通Speed Pass」意見回報&body=內容：請填寫您所要詢問的內容，我們將安排專人回覆，感謝您的配合。%0A%0A%0A揚揚創新科技%20YOUNG%20SQUARE%20CREATECH">聯絡揚揚</a>
                        </li>

                    </ul>
                </div>

            </div>
        </nav>

        <div class="container pb-5">
            <div class="row pt-5">

                <div class="col-12 order-md-1">
                    <h4 class="mb-3">店家資訊</h4>

                    <form class="needs-validation" @submit.prevent="addLocation" novalidate>

                        <div class="form-group row">
                            <label for="userName" class="col-sm-3 col-form-label">店家名稱<br>Store name</label>
                            <div class="col-sm-9">
                                <input type="text" id="userName" class="form-control"  v-model="locationName" placeholder="請輸入" required>
                                <small id="userNameHelp" class="form-text text-muted">有分店名稱請一併填入此欄位。例：星巴克-長安門市</small>
                                <div class="invalid-feedback">請務必輸入店家全名</div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="userNumber" class="col-sm-3 col-form-label">店家電話<br>Store phone number</label>
                            <div class="col-sm-9">
                                <input type="tel" pattern="^(\d+-?)+\d+$" id="userNumber" class="form-control" v-model="locationPhoneNumber" placeholder="請輸入" required>
                                <small id="userNumberHelp" class="form-text text-muted">市話，請填寫區號。例：02-26xxxxxx</small>
                                <div class="invalid-feedback">請務必輸入店家電話</div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="userLocation" class="col-sm-3 col-form-label">店家地址<br>Store Address</label>
                            <div class="col-sm-9">
                                <input type="text" id="userLocation" class="form-control" v-model="locationAddress" placeholder="商家地址" required>
                                <div class="invalid-feedback">請務必輸入店家地址</div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="userEmail" class="col-sm-3 col-form-label">連絡信箱<br>Email</label>
                            <div class="col-sm-9">
                                <input type="email" id="userEmail" class="form-control" v-model="email" placeholder="請填寫連絡信箱" required>
                                <small id="userEmailHelp" class="form-text text-muted">請填寫可收取每日入場紀錄之Email，並做好資料良善管理之責任。入場紀錄將在每日結束後系統安排隔日送出信給申請單位。</small>
                                <div class="invalid-feedback">請務必輸入店家連絡信箱</div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="userSeat" class="col-sm-3 col-form-label">座位數<br>Number of Seat</label>
                            <div class="col-sm-9">
                                <input type="number" id="userSeat" class="form-control" v-model="seatingCapacity" placeholder="請輸入">
                                <small id="userSeatHelp" class="form-text text-muted">請填寫之室內可用座位數。</small>
                            </div>
                        </div>


                        <div class="form-group">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="invalidCheck" v-model="policyCheck" required>
                                <label class="form-check-label small" for="invalidCheck">
                                    同意事項（必勾選，若無法勾選將無法完成本服務申請，也無法使用本服務。）<br>
                                    本服務單位與服務場所因疫情管理需使用實聯制進行入場實證管理，今日同意並申請使用實聯快疫通服務，並同意提供專人管理之信箱收取由「實聯快疫通」每日提供之入場資訊， 更將秉持良善場所營運者之準則與政府相關法規保密並予以妥善保管，並保證不將資料流為他用。及為防堵疫情而有必要時，得提供衛生主管機關依傳染病防治法等規定進行疫情調查及聯繫使用。
                                </label>
                                <div class="invalid-feedback">必勾選，若無法勾選將無法完成本服務申請，也無法使用本服務。</div>
                            </div>
                        </div>

                        <hr class="mb-4">

                        <button class="btn btn-primary btn-lg btn-block mt-4" type="submit">免費申請</button>
                    </form>
                </div>
            </div>

            <footer class="my-5 pt-5 text-muted text-small">
                <p class="mb-1">揚揚創新科技有限公司 YOUNG SQUARE CREATECH</p>
                <p class="mb-3">
                    Email: info@yscreatech.com <br>
                </p>
                <p class="mb-1">© 2014-2021 Young Square Createch LTD.</p>
            </footer>

            <div class="login_power">power by <span><img src="/images/img_logo_w.png"></span></div>
        </div>
    </div>
</template>

<script>
    import axios from "axios";
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';

    export default {
        name: "CreateStore",
        mounted() {
            let forms = document.getElementsByClassName('needs-validation')
            let validation = Array.prototype.filter.call(forms, function(form) {
                form.addEventListener('submit', function(event) {
                    if (form.checkValidity() === false) {
                        event.preventDefault();
                        event.stopPropagation();
                    }
                    form.classList.add('was-validated');
                }, false);
            })
        },
        data: function() {
            return {
                locationPhoneNumber: '',
                locationName: '',
                locationAddress: '',
                email: '',
                seatingCapacity: '',
                policyCheck: false,
                isLoading: false,
                fullPage: true
            }
        },
        components: {
            Loading
        },
        methods: {
            addLocation: function() {
                if (this.locationName.length !== 0 && this.locationAddress.length !== 0 && this.locationPhoneNumber.length !== 0 &&
                    this.email.length !== 0 && this.policyCheck === true) {
                    if(!this.validEmail(this.email)) {
                        alert('您所填寫的電子郵件信箱格式錯誤，請修正後重新送出')
                    } else {
                        this.isLoading = true
                        axios.post('https://jj7lnyhmk0.execute-api.ap-southeast-1.amazonaws.com/TEST/createlocation',
                            { locationName: this.locationName, address: this.locationAddress, phoneNumber: this.locationPhoneNumber, email: this.email, seatingCapacity: this.seatingCapacity })
                            .then((res) => {
                                const result = JSON.parse(res.data.body)
                                if(result.MESSAGE === 'SUCCESS') {
                                    this.redirect(result.SID)
                                }
                            })
                            .catch((error) => { console.error(error) })
                    }
                }
            },
            redirect: function(sid) {
                this.$router.push({
                    name: 'StoreBoard',
                    params: {locationSid: sid}
                })
            },
            validEmail: function (email) {
                var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(email);
            }
        }

    }
</script>

<style scoped>

</style>
